import { createAction, handleActions } from 'redux-actions';
import { fetchPageByUID } from './prismicFetch';
import { CONFIG } from 'constants/config';

const SET_PIONEER_CONFIG = 'SET_PIONEER_CONFIG';
const SET_DISABLE_PROFILES = 'SET_DISABLE_PROFILES';

const initialState = {
    pioneer31: false,
    disableProfiles: false
};

const setPioneer31 = createAction(SET_PIONEER_CONFIG);
export const setDisableProfiles = createAction(SET_DISABLE_PROFILES);

export const getPioneerConfig = () => (dispatch, getState) => {
    return Promise.resolve(
        dispatch(fetchPageByUID('pioneer_config', CONFIG.PIONEER_PRISMIC_UID))
    ).then(() => {
        const { enable_r3_1 } = getState().prismic.documents[
            CONFIG.PIONEER_PRISMIC_UID
        ].data;
        return dispatch(setPioneer31(enable_r3_1));
    });
};

export default handleActions(
    {
        [SET_PIONEER_CONFIG]: (state, { payload }) => ({
            ...state,
            pioneer31: payload
        }),
        [SET_DISABLE_PROFILES]: (state, { payload }) => ({
            ...state,
            disableProfiles: payload
        })
    },
    initialState
);
