import { createAction, handleActions } from 'redux-actions';
import { FETCH_SUCCESS } from 'middlewares/fetch';
import { PRISMIC_ENDPOINTS } from 'constants/prismic';
import {
    getMakeupArtists,
    getResidencyArtists,
    getVirtualArtists,
    setMakeupArtistPageIds,
    setResidencyArtistPageIds,
    setVirtualArtistPageIds
} from './makeupArtists';
import { PRISMIC, PRISMIC_MULTIPLE, PRISMIC_ERROR, PRISMIC_FETCH } from 'middlewares/prismicFetch';
import { setDisableProfiles } from './config';

// Prismic document type for events
export const ATELIER_EVENTS_DOCTYPE = 'atelier-event';

const initialState = {
    documents: {},
    isAtelierReady: false,
    error: null
};

export const isAtelierReady = createAction('ATELIER_READY');

export const fetchAtelierData = createAction(PRISMIC_FETCH, (template, uid) => ({
    prefix: PRISMIC,
    endpoint: PRISMIC_ENDPOINTS.GET_BY_UID,
    args: [template, uid, {}],
    key: 'atelier'
}));

export const fetchPagesByID = createAction(PRISMIC_FETCH, (pageIds) => ({
    prefix: PRISMIC_MULTIPLE,
    endpoint: PRISMIC_ENDPOINTS.GET_BY_IDS,
    args: [pageIds, {}]
}));

export const fetchPageByName = createAction(PRISMIC_FETCH, (pageName) => ({
    prefix: PRISMIC,
    endpoint: PRISMIC_ENDPOINTS.GET_SINGLE,
    args: [pageName],
    key: 'page'
}));

export const fetchPageByUID = createAction(PRISMIC_FETCH, (pageName, uid) => ({
    prefix: PRISMIC,
    endpoint: PRISMIC_ENDPOINTS.GET_BY_UID,
    args: [pageName, uid, {}],
    key: 'uid'
}));

export const fetchAllDocumentsByType = createAction(PRISMIC_FETCH, (documentType) => ({
    prefix: PRISMIC,
    endpoint: PRISMIC_ENDPOINTS.GET_BY_DOCTYPE,
    args: [documentType],
    key: documentType
}));

/**
 * Fetch the root atelier Prismic document.
 * @param {string} atelierId
 * @returns {function} - Dispatched action for atelier data
 */
export const fetchAtelier = (atelierId) => {

    return (dispatch, getState) => {
        return dispatch(fetchAtelierData('atelier', atelierId))
            .then(() => {
                const { prismic: { documents: { atelierData: { data } } } } = getState();
                const prismicDataPromises = [
                    dispatch(fetchPagesByID([data.header.id, data.footer.id])), // get header and footer
                    dispatch(setDisableProfiles(data.disable_profiles)),
                ];

                Promise.all(prismicDataPromises).then(() => {
                    return dispatch(isAtelierReady(true));
                });
            });
    };
};

/**
 * Fetch only MUA data from Prismic.
 * @param {string} atelierId
 * @returns {function} - Dispatched action for atelier data
 */
export const fetchAtelierMUAData = (atelierId) => {
    return (dispatch, getState) => {
        dispatch(isAtelierReady(false));

        return dispatch(fetchAtelierData('atelier', atelierId))
            .then(() => {
                const { prismic: { documents: { atelierData: { data } } } } = getState();
                dispatch(setMakeupArtistPageIds(data.makeup_artists.map(page => page.makeup_artist.id)));
                dispatch(setResidencyArtistPageIds(data.residency_artists.map(page => page.residency_artist.id)));
                dispatch(setVirtualArtistPageIds(data.virtual_artists.map(page => page.virtual_artist.id)));

                Promise.all([
                    dispatch(getMakeupArtists()),
                    dispatch(getResidencyArtists()),
                    dispatch(getVirtualArtists())
                ]).then(() => dispatch(isAtelierReady(true)));
            });
    };
};

export default handleActions(
    {
        [`${PRISMIC}/atelier`]: (state, { payload }) => ({
            ...initialState,
            ...state,
            documents: {
                ...state.documents,
                atelierData: payload
            }
        }),
        [`${PRISMIC}/page`]: (state, { payload }) => ({
            ...initialState,
            ...state,
            documents: {
                ...state.documents,
                [payload.type]: payload
            }
        }),
        [`${PRISMIC}/uid`]: (state, { payload }) => ({
            ...initialState,
            ...state,
            documents: {
                ...state.documents,
                [payload.uid]: payload
            }
        }),
        [`${PRISMIC}/${ATELIER_EVENTS_DOCTYPE}`]: (state, { payload }) => ({
            ...initialState,
            ...state,
            documents: {
                ...state.documents,
                [ATELIER_EVENTS_DOCTYPE]: payload.results.map(r => r.data)
            }
        }),
        [`${PRISMIC_MULTIPLE}/${FETCH_SUCCESS}`]: (state, { payload }) => {
            const prismicDocuments = {};
            payload.results.forEach(document => prismicDocuments[document.id] = document);
            return {
                ...initialState,
                ...state,
                documents: {
                    ...state.documents,
                    ...prismicDocuments
                }
            };
        },
        [`${PRISMIC_ERROR}`]: (state, { payload }) => {
            return {
                ...initialState,
                ...state,
                error: payload
            };
        },
        [isAtelierReady]: (state, { payload }) => ({
            ...initialState,
            ...state,
            isAtelierReady: payload
        })
    },
    initialState
);

/**
 * Returns the documents that have been added to the Prismic state.
 * @param {object} prismicState
 * @returns {object[]} - Prismic fetched documents
 */
export const getPrismicData = (prismicState) => {
    return Object.values(prismicState.documents);
};
