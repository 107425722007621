import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import user from './user';
import login from './login';
import logout from './logout';
import checkIn from './checkIn';
import globalLayout from './globalLayout';
import prismicReducer from './prismicFetch';
import staff from './staff';
import services from './services';
import transactions from './transactions';
import config from './config';

const authPersistConfig = {
    key: 'user',
    storage: storage,
    whitelist: ['auth']
};

const checkinPersistConfig = {
    key: 'checkIn',
    storage,
    whitelist: ['checkInData']
};

const reducers = (routerReducer) => combineReducers({
    router: routerReducer,
    user: persistReducer(authPersistConfig, user),
    prismic: prismicReducer,
    config,
    login,
    logout,
    checkIn: persistReducer(checkinPersistConfig, checkIn),
    globalLayout,
    staff,
    services,
    transactions
});

export default reducers;
