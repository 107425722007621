import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ROUTES } from 'constants/routes';
import { rem, greaterThan } from 'core/styled';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setHeaderShrunkState, HEADER_ICONS, setHeaderTicketVisible, setIsHeaderSearchOpen } from 'reducers/globalLayout';
import { Flex, Box } from 'components/GlobalComponents/FlexBox/index';
import { LAYER } from 'constants/layers';
import { push } from 'redux-first-history';
import windowScrollPosition from 'core/utils/windowScrollPosition';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import SkipLinks from 'components/SkipLinks/SkipLinks';

// images
import { breakpoints, theme } from 'constants/theme';

// icons
import logo from 'assets/logo.svg';
import logoInverted from 'assets/white-logo.svg';
import newLogo from 'assets/logo-alternative.svg';
import newLogoInverted from 'assets/logo-alternative-inverted.svg';
import logoShrunk from 'assets/logo_shrunk.svg';
import logoShrunkInverted from 'assets/logo_shrunk-inverted.svg';
import logoParfum from 'assets/fragranceApp/logo-parfum-black.svg';
import logoParfumInverted from 'assets/fragranceApp/logo-parfum-white.svg';
import headerEdge from 'assets/perf-edge-white.svg';
import headerEdgeInverted from 'assets/perf-edge-black.svg';

import { analyticsTag } from 'reducers/analytics';
import { EVENTS } from 'constants/analytics';

import Icon from 'components/GlobalComponents/Icons/Icon';
import { ICON_TYPES } from 'components/GlobalComponents/Icons/IconVariants';

const MENU_BLOCK_WIDTH = '25%';

// TODO: after VX validates, add cross-browser support and ability to change gradient color from props
// GRADIENT: https://www.cssmatic.com/gradient-generator#'\-moz\-linear\-gradient\%28top\%2C\%20rgba\%28255\%2C255\%2C255\%2C1\%29\%200\%25\%2C\%20rgba\%28255\%2C255\%2C255\%2C0\.64\%29\%2060\%25\%2C\%20rgba\%28255\%2C255\%2C255\%2C0\.4\%29\%20100\%25\%29\%3B'
const HeaderWrapper = styled(Flex)`
    box-sizing: border-box;
    width: 100%;
    background: ${({ headerBackground }) => headerBackground};
    z-index: ${LAYER.HEADER};
    position: fixed;
    padding: ${({ isImpersonating }) => isImpersonating ? rem(140, 0, 0, 0) :  rem(0, 0, 0, 0)};
    flex-direction: column;
    ${({ showBorder, headerBackground }) => showBorder ? (headerBackground === theme.color.white || headerBackground === 'white' ? `border-bottom: 1px solid ${theme.color.dustyGrey}` : `border-bottom: 1px solid ${headerBackground}`) : ''};
`;

const HeaderContentWrapper = styled.header`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const HeaderInner = styled(Flex)`
    width: 100%;
    z-index: ${LAYER.HEADER};
    background: ${({ headerBackground }) => headerBackground};
    padding: ${({ isImpersonating, parfumFullLogo }) => isImpersonating ? (parfumFullLogo ? rem(180, 15, 5, 15) : rem(160, 15, 12, 15)) : (parfumFullLogo ? rem(32, 15, 5, 15) : rem(12, 15, 12, 15))};

    ${greaterThan(breakpoints.small)(css`
        padding: ${({ isImpersonating, parfumFullLogo }) => isImpersonating ? (parfumFullLogo ? rem(180, 15, 5, 15) : rem(165, 15, 17, 15)) : (parfumFullLogo ? rem(32, 15, 5, 15) : rem(17, 15, 17, 15))};
    `)}
`;

const LogoImg = styled.img`
    width: ${({ parfumLogo }) => parfumLogo ? '111px' : '140px'};
    height: ${({ parfumLogo }) => parfumLogo ? '70px' : '30px'};
    transition: all 0.6s;
    position: relative;
    margin-top: ${({ shrinkHeader }) => shrinkHeader ? '-30px' : '0'};
    
    ${greaterThan(breakpoints.small)(css`
        width: ${({ parfumLogo }) => parfumLogo ? '111px' : '180px'};
        height: ${({ parfumLogo }) => parfumLogo ? '70px' : '40px'};
    `)}
`;

const StyledLogo = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    opacity: ${props => props.showLogo ? 1 : 0};
    transition: all 0.2s;
    height: ${({ parfumLogo, isHeaderShrunk }) => parfumLogo ? (isHeaderShrunk ? '45px' : '70px') : '36px'};
    
    ${greaterThan(breakpoints.small)(css`
        height: ${({ parfumLogo, isHeaderShrunk }) => parfumLogo ? (isHeaderShrunk ? '45px' : '70px') : '46px'};
    `)}
`;

const IconWrapper = styled(Flex)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const IconOuter = styled(Box)`
  flex: none;
  width: 40px;
`;

const MenuBlock = styled(Flex)`
    width: ${MENU_BLOCK_WIDTH};
    justify-content: ${({ isLogin }) => isLogin ? 'space-around' : 'flex-start'};
    
    ${greaterThan(breakpoints.small)(css`
        padding-left: 2.5vw;
        justify-content: flex-start;
         > div {
            margin: 0 5px; 
         }
    `)}
`;
const AccountBlock = styled(Flex)`
    width: ${MENU_BLOCK_WIDTH};
    justify-content: ${({ isLogin }) => isLogin ? 'space-around' : 'flex-end'};
    
    ${greaterThan(breakpoints.small)(css`
        padding-right: 2.5vw;
        justify-content: flex-end;
         > div {
            margin: 0 10px; 
         }
    `)}
`;
const LogoBlock = styled(Flex)`
    width: ${MENU_BLOCK_WIDTH};
    justify-content: center;
`;

const iconsSets = {
    [HEADER_ICONS.DEFAULT]: {
        logo: logo,
        newLogo: newLogo,
        logoShrunk: logoShrunk,
        logoParfum: logoParfum,
        headerEdge: headerEdge
    },
    [HEADER_ICONS.INVERTED]: {
        logo: logoInverted,
        newLogo: newLogoInverted,
        logoShrunk: logoShrunkInverted,
        logoParfum: logoParfumInverted,
        headerEdge: headerEdgeInverted
    }
};

const headerAnalytics = {
    event: EVENTS.GA,
    eventCategory: 'header'
};

class PageMobileHeader extends Component {

    static propTypes = {
        isLoggedIn: PropTypes.bool.isRequired,
        headerBackground: PropTypes.string.isRequired,
        setHeaderShrunkState: PropTypes.func.isRequired,
        isFragrance: PropTypes.bool.isRequired,
        isHeaderStaffTheme: PropTypes.bool.isRequired,
        headerIcons: PropTypes.string.isRequired,
        analyticsTag: PropTypes.func.isRequired,
        toggleHamburger: PropTypes.func,
        inverted: PropTypes.bool,
        isHeaderShrunk: PropTypes.bool,
        showLogo: PropTypes.bool,
        push: PropTypes.func.isRequired,
        showFullLogo: PropTypes.bool,
        useParfumLogo: PropTypes.bool,
        showHeaderTicket: PropTypes.bool,
        overrideHeaderTicketBehavior: PropTypes.bool,
        setHeaderTicketVisible: PropTypes.func.isRequired,
        disableHeaderShrink: PropTypes.bool,
        isImpersonating: PropTypes.bool,
        noImpersonation: PropTypes.bool,
        profile: PropTypes.object,
        automaticSearchExpansion: PropTypes.bool,
        isHeaderSearchOpen: PropTypes.bool.isRequired,
        setIsHeaderSearchOpen: PropTypes.func.isRequired,
        workshopServerError: PropTypes.bool,
        showHamburgerMenu: PropTypes.bool.isRequired,
        forcedWhiteTicketHeader: PropTypes.bool
    };

    static defaultProps = {
        showLogo: true,
        disableHeaderShrink: false,
        automaticSearchExpansion: false,
        workshopServerError: false
    };

    state = {
        isModalVisible: false
    };

    searchInputRef = React.createRef();
    searchButtonRef = React.createRef();

    /**
     * Send analytics tag
     * @param {object} properties
     */
    analytics = (properties) => {
        this.props.analyticsTag({
            ...headerAnalytics,
            ...properties
        }, { userInfo: true });
    };

    componentDidMount() {
        // Need to bind to parent container scroll, because header itself doesn't scroll
        this.parentContainer = window;
        this.parentContainer.addEventListener('scroll', this.handleScroll, { passive: true });
    }

    componentWillUnmount() {
        this.parentContainer.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * Handle page scroll
     */
    handleScroll = () => {
        const { isHeaderShrunk, setHeaderShrunkState, setHeaderTicketVisible, overrideHeaderTicketBehavior, disableHeaderShrink, showHeaderTicket, automaticSearchExpansion, isHeaderSearchOpen, setIsHeaderSearchOpen } = this.props;
        const scrollTop = windowScrollPosition();
        const shouldTrigger = scrollTop > 0;

        if (!disableHeaderShrink && isHeaderShrunk !== shouldTrigger) {
            setHeaderShrunkState(shouldTrigger);
        }

        if (!overrideHeaderTicketBehavior && showHeaderTicket !== shouldTrigger) {
            setHeaderTicketVisible(shouldTrigger);
        }

        if (automaticSearchExpansion && isHeaderSearchOpen !== shouldTrigger) {
            setIsHeaderSearchOpen(shouldTrigger);
        }
    };

    /**
     * Determine if we need to display the parfum logo in full size
     * @returns {boolean} - Boolean indicating logo is parfum
     */
    isParfumLogo = () => {
        const { isFragrance, useParfumLogo } = this.props;

        return isFragrance || useParfumLogo;
    };


    /**
     * Renders the header based on the context.
     * @returns {object} Component
     */
    renderHeader = () => {
        const { toggleHamburger, isLoggedIn, isHeaderStaffTheme, headerIcons, push,
            headerBackground, showHamburgerMenu } =  this.props;

        const iconsVariant = headerIcons === 'inverted' ? 'dark' : 'light';
        const iconPixelHeight = 19;

        return (
            <HeaderInner alignItems='center' justifyContent='space-between' headerBackground={headerBackground}>
                <MenuBlock isLogin={isLoggedIn} id='atelier--header-menublock'>
                    {isHeaderStaffTheme && <IconOuter>
                        <IconWrapper>
                            <NoStyleButton type={'button'} aria-expanded={showHamburgerMenu} onClick={() => {
                                toggleHamburger();
                                this.analytics({ eventAction: 'menu' });
                            }}>
                                <Icon
                                    type={ICON_TYPES.MENU}
                                    variant={iconsVariant}
                                    pixelHeight={iconPixelHeight}
                                    alt={showHamburgerMenu ? 'close menu' : 'open menu'}
                                />
                            </NoStyleButton>
                        </IconWrapper>
                    </IconOuter>}
                </MenuBlock>
                <LogoBlock justifyContent={'center'}>
                    { this.renderLogo() }
                </LogoBlock>
                <AccountBlock isLogin={isLoggedIn} id='atelier--header-accountblock'>
                    {isHeaderStaffTheme &&
                    <IconOuter>
                        <IconWrapper>
                            <a href={ROUTES.PROFILE} onClick={e => {
                                e.preventDefault();
                                push(ROUTES.PROFILE);
                            }}>
                                <Icon
                                    type={ICON_TYPES.PROFILE}
                                    variant={iconsVariant}
                                    pixelHeight={iconPixelHeight}
                                    alt={'Go to the profile page'}
                                />
                            </a>
                        </IconWrapper>
                    </IconOuter>}

                </AccountBlock>
            </HeaderInner>
        );
    };

    /**
     * Renders the logo based on the context.
     * @returns {object} Component
     */
    renderLogo = () => {
        //const { isHeaderShrunk, showLogo, headerIcons, isFragrance, showFullLogo, useParfumLogo, isLoggedIn } = this.props;
        const { showLogo, headerIcons, isLoggedIn, isFragrance, useParfumLogo, isHeaderShrunk, workshopServerError } = this.props;
        const icons = iconsSets[headerIcons];

        // show either full or shrunk logo, and handle parfum experience logo
        // const logoImage  = isHeaderShrunk && !showFullLogo ? icons.logoShrunk :
        //     isFragrance || useParfumLogo ? icons.logoParfum : icons.logo;

        const logoImage = isFragrance || useParfumLogo ? (isHeaderShrunk ? icons.logoShrunk : icons.logoParfum) : icons.newLogo;

        // don't shrink header if showing full logo
        // const shrinkHeader = isHeaderShrunk && !showFullLogo;

        if (workshopServerError) {
            return (
                <StyledLogo showLogo parfumLogo={false} isHeaderShrunk={false} id='atelier--logo'>
                    <LogoImg
                        src={icons.newLogo}
                        alt='Atelier Beaute CHANEL, go to the homepage'
                        onClick={() => {
                            this.analytics({ eventAction: 'logo' });
                            window.location.pathname = ROUTES.HOME;
                        }}
                        shrinkHeader={false}
                        parfumLogo={false}
                        role={'img'}
                    />
                </StyledLogo>
            );
        }

        return (
            <Link to={ROUTES.HOME}>
                <StyledLogo showLogo={showLogo} parfumLogo={this.isParfumLogo()} isHeaderShrunk={isHeaderShrunk} id='atelier--logo'>
                    <LogoImg
                        src={logoImage}
                        alt={ isLoggedIn ? 'Atelier Beaute CHANEL, go to profile' : 'Atelier Beaute CHANEL, go to the homepage' }
                        onClick={() => this.analytics({ eventAction: 'logo' })}
                        shrinkHeader={this.isParfumLogo() && isHeaderShrunk}
                        parfumLogo={this.isParfumLogo()}
                        role={'img'}
                    />
                </StyledLogo>
            </Link>
        );
    };

    render() {
        const { isFragrance, headerBackground, isImpersonating, noImpersonation, useParfumLogo } =  this.props;

        // We don't want to display the header bottom border when we have a transparent header. The HeaderTicket
        // component will display the border instead.
        const shouldDisplayHeaderWrapperBorder = headerBackground !== theme.color.transparent && !useParfumLogo;

        return (
            <HeaderWrapper
                id={'main-header-wrapper'}
                isImpersonating={isImpersonating && !noImpersonation}
                isFragrance={isFragrance}
                justifyContent='center'
                fullHeight={false}
                headerBackground={headerBackground}
                showBorder={shouldDisplayHeaderWrapperBorder}
            >
                <SkipLinks/>
                <HeaderContentWrapper role={'banner'} tabIndex={-1}>
                    {this.renderHeader()}
                </HeaderContentWrapper>
            </HeaderWrapper>
        );
    }
}

const mapStateToProps = ({
    user: { auth: { profile, authenticated, isImpersonating } },
    globalLayout: {
        headerBackground,
        headerTheme,
        isFragrance,
        isHeaderShrunk,
        isHeaderStaffTheme,
        headerIcons,
        useParfumLogo,
        showHeaderTicket,
        overrideHeaderTicketBehavior,
        inverted,
        isHeaderSearchOpen,
        forcedWhiteTicketHeader
    },
}) => ({
    profile,
    isLoggedIn: authenticated,
    isHeaderStaffTheme,
    headerBackground,
    headerTheme,
    isFragrance,
    isImpersonating,
    isHeaderShrunk,
    headerIcons,
    useParfumLogo,
    showHeaderTicket,
    overrideHeaderTicketBehavior,
    inverted,
    isHeaderSearchOpen,
    forcedWhiteTicketHeader,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { push, setHeaderShrunkState, analyticsTag , setHeaderTicketVisible, setIsHeaderSearchOpen },
        dispatch
    );


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageMobileHeader));
