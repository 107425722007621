import { createStore } from 'redux';
import reducers from 'reducers';
import middlewares from 'middlewares';
import { persistStore } from 'redux-persist';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import confirm from 'core/utils/confirm';

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({
    history: createBrowserHistory({
        getUserConfirmation(message, callback) {
            confirm(message).then(
                () => callback(true),
                () => callback(false)
            );
        }
    })
});

export const store = createStore(
    reducers(routerReducer),
    middlewares(routerMiddleware)
);
// after rehydration is complete, validate the check-in data
export const persistor = persistStore(store);

export const history = createReduxHistory(store);
