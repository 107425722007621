import { ROUTES } from './routes';

export const MENU_LINKS = {
    CUSTOMER: [
        {
            text: 'HOME',
            link: ROUTES.HOME
        }
    ],
    CUSTOMER_LOGIN: [
        {
            text: 'HOME',
            link: ROUTES.HOME
        }
    ],
    STAFF: [
        {
            text: 'MY DASHBOARD',
            link: ROUTES.PROFILE
        },
    ],
    CUSTOMER_SUB_LINKS: [
    ],
    CUSTOMER_LOGIN_SUB_LINKS: [
    ],
    STAFF_SUB_LINKS: [
        {
            text: 'REFUNDS',
            link: ROUTES.REFUNDS,
            freelancerHidden: true
        }
    ]
};
